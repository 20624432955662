import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import Header from "./Header2";
import Navbar from "./Navbar";

const About = () => {
    const [t, i18n] = useTranslation()
    const Title = "titleAbout"
    return (
        <div className="about">
            <Navbar />
            <Header Title={Title} />
            <div className="container">
                <div className="aboutCont">
                    <h4>  {t("subTitleAblut")} </h4>
                    <p> {t("aboutP1")} </p>
                </div>
                <div className="subAbout">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-5 aboutImg aboutImg1">
                            <img src="https://www.careem.com/pubweb-v2/about-us/images/leaders.jpg" />
                        </div>
                        <div className="col-12 col-md-7 aboutCont1">
                            <h4> {t("subTitleAbout1")} </h4>
                            <p> {t(("aboutp2"))} </p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-12 col-md-7 aboutCont2">
                            <h4> {t("subTitleAbout2")} </h4>
                            <p> {t(("aboutp3"))} </p>
                        </div>
                        <div className="col-12 col-md-5 aboutImg aboutImg2">
                            <img src="https://www.careem.com/pubweb-v2/about-us/images/careers.jpg" />
                        </div>
                    </div>
                </div>
                <div className="subAbout2">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="subAbout2Child aboutImg1">
                                <img src="https://www.careem.com/pubweb-v2/about-us/images/newsroom.jpg" />
                                <div>
                                    <h4>  {t("subAboutChildH41")} </h4>
                                    <p>
                                        {t("subAboutChildP1")}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="subAbout2Child aboutImg2">
                                <img src="https://www.careem.com/pubweb-v2/about-us/images/becareem-ar.jpg" />
                                <div>
                                    <h4>  {t("subAboutChildH42")} </h4>
                                    <p>
                                        {t("subAboutChildP2")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default About;