/* eslint-disable jsx-a11y/anchor-is-valid */

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

/* eslint-disable jsx-a11y/alt-text */
const Footer = () => {
    const [t] = useTranslation()

    return (
        <div className="footer">
            <div className="footerTop">
                <div className="container">
                    <div className="footerTopCont">
                        <img src="./assets/Images/lorry-logo-white.png" />
                        <div className="footerMenue">
                            <ul>
                                <li>
                                    <Link to="/TermsOfService"> {t("TermsOfService")} </Link>
                                </li>
                                <li>
                                    <Link to="/PrivacyPolicy"> {t("privacypolicy")} </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footerBott">
                <div className="container">
                    <div className="footerBottCont">
                        <div className="footerBottText">
                            <p>All rights reserved. lorry, an Uber company © 2021</p>
                        </div>
                        <div className="footerBootIcon">
                            <ul>
                                <li>
                                    <a href="#">
                                        <i className="fa-brands fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fa-brands fa-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fa-brands fa-linkedin-in"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;