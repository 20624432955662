import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import Navbar from "./Navbar";

const PrivacyPolicy = () => {
    const [t] = useTranslation()
    return (
        <div>
            <Navbar />
            <div className="PrivacyPolicy">
                <div className="container">
                    <h3> {t('privacypolicy')} </h3>
                    <div className="termsCont">
                        <h5> {t("PrivacyTitle")} </h5>
                        <p>
                            {t('PrivacyP')}
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default PrivacyPolicy;