import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import Header from "./Header2";
import Navbar from "./Navbar";

const Joinourteam = () => {
    const [t, i18n] = useTranslation()
    const Title = 'titleTeam'
    return (
        <div className="joinTeam">
            <div className="about">
                <Navbar />
                <Header Title={Title} />
                <div className="container">
                    <div className="subAbout">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-5 aboutImg">
                                <img src="https://www.careem.com/pubweb-v2/careers/images/careers.jpg" />
                            </div>
                            <div className="col-12 col-md-7 aboutImgText">
                                <h4> {t("JoinourJourney")} </h4>
                                <p> {t(("PJoinourJourney"))} </p>
                            </div>
                        </div>
                        <div className="subsafety">
                            <div className="row align-items-center ">
                                <div className="col-12 col-md-4">
                                    <div className="subsefetyChildJoinTeam">
                                        <img src="https://www.careem.com/pubweb-v2/careers/images/to-the-moon.png" />
                                        <div className="subsefetyCont">
                                            <h5> {t("TitleJoin1")} </h5>
                                            <p> {t("PJoin1")} </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="subsefetyChildJoinTeam">
                                        <img src="https://www.careem.com/pubweb-v2/careers/images/be-of-service.png" />
                                        <div className="subsefetyCont">
                                            <h5> {t("TitleJoin2")} </h5>
                                            <p> {t("PJoin2")} </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="subsefetyChildJoinTeam">
                                        <img src="https://www.careem.com/pubweb-v2/careers/images/be-an-owner.png" />
                                        <div className="subsefetyCont">
                                            <h5> {t("TitleJoin3")} </h5>
                                            <p> {t("PJoin3")} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Joinourteam;