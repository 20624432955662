import Advantages from "./Advantages";
import Category from "./Category";
import Explore from "./Explore";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";
import Packages from "./Packages";
import Steps from "./Steps";

const Home = () => {
    return (
        <>
            <Navbar />
            <Header />
            <Advantages />
            <Steps />
            <Category />
            <Packages />
            <Explore />
            <Footer />
        </>
    );
}

export default Home;