import './App.css';
import Home from './components/Home';
import Footer from './components/Footer';
import { useRoutes } from 'react-router-dom';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import Header from './components/Header';
import Navbar from './components/Navbar';
import Sidbar from './components/Sidbar';
import About from './components/About';
import Safety from './components/Safety';
import Joinourteam from './components/Joinourteam';
import 'animate.css';

function App() {
  const routes = useRoutes([
    {
      path: '/home',
      element: <Home />
    },
    {
      path: '/',
      element: <Home />
    },
    {
      path: '/PrivacyPolicy',
      element: <PrivacyPolicy />
    },
    {
      path: '/TermsOfService',
      element: <TermsOfService />
    },
    {
      path: '/about',
      element: <About />
    },
    {
      path: '/safety',
      element: <Safety />
    },
    {
      path: '/Joinourteam',
      element: <Joinourteam />
    }
  ])
  return (
    <div className="App">
      {/* <Navbar /> */}
      {/* <Header /> */}
      {routes}
      {/* <Footer /> */}
    </div>
  );
}

export default App;
