import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Sidbar from "./Sidbar";

const Navbar = () => {
    const [t, i18n] = useTranslation()
    const direction = () => {
        const body = document.querySelector('body')
        const App = document.querySelector('.App')
        if (body.style.direction === 'ltr') {
            body.style.direction = 'rtl'
            App.classList.remove('eg')

        } else {
            body.style.direction = 'ltr'
            App.classList.add('eg')
        }

    }
    const toogleSidbar = () => {
        const sidbar = document.querySelector('.sidbar')
        sidbar.style.right = '0'
    }
    return (
        <nav className="navbar">
            <Sidbar />
            <div className="container">
                <div>
                    <i onClick={toogleSidbar} className="fa-solid fa-bars"></i>
                    <Link to='/'> <img src="./assets/Images/lorry-logo-white.png" /> </Link>
                </div>
                <div>
                    {
                        i18n.language === 'en' && <button onClick={() => { i18n.changeLanguage('ar') && direction() }}>AR</button>
                    }
                    {
                        i18n.language === 'ar' && <button onClick={() => { i18n.changeLanguage('en') && direction() }}>EG</button>
                    }
                </div>
            </div >
        </nav>
    );
}

export default Navbar;