import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Category = () => {
    const [t] = useTranslation()
    return (
        <div className="category">
            <h3>اختر فئة السيارة التي تريدها</h3>
            <div className="tabsCont">
                <div className="container">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                                <img src="https://www.careem.com/pubweb-v2/ride/images/ccts/standard.png" alt="icon"></img>
                                <h5> {t("titleCar1")} </h5>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                <img src="https://www.careem.com/pubweb-v2/ride/images/ccts/premium.png" alt="icon"></img>
                                <h5> {t("titleCar2")} </h5>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">
                                <img src="https://www.careem.com/pubweb-v2/ride/images/ccts/budget.png" alt="icon"></img>
                                <h5> {t("titleCar1")} </h5>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="contact1-tab" data-bs-toggle="tab" data-bs-target="#contact1" type="button" role="tab" aria-controls="contact1" aria-selected="false">
                                <img src="https://www.careem.com/pubweb-v2/ride/images/ccts/pooling.png" alt="icon"></img>
                                <h5> {t("titleCar4")} </h5>
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-12 col-lg-6 contcategoryimg">
                                    <img src="https://www.careem.com/pubweb-v2/ride/images/ccts/standard.png" alt="icon"></img>
                                </div>
                                <div className="col-12 col-lg-6 contcategorytext">
                                    <h5> {t("titleCar1")} </h5>
                                    <p> {t("pCar1")} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-12 col-lg-6 contcategoryimg">
                                    <img src="https://www.careem.com/pubweb-v2/ride/images/ccts/premium.png" alt="icon"></img>
                                </div>
                                <div className="col-12 col-lg-6 contcategorytext">
                                    <h5> {t("titleCar2")} </h5>
                                    <p> {t("pCar2")} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-12 col-lg-6 contcategoryimg">
                                    <img src="https://www.careem.com/pubweb-v2/ride/images/ccts/budget.png" alt="icon"></img>
                                </div>
                                <div className="col-12 col-lg-6 contcategorytext">
                                    <h5> {t("titleCar3")} </h5>
                                    <p> {t("pCar3")} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="contact1" role="tabpanel" aria-labelledby="contact1-tab">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-12 col-lg-6 contcategoryimg">
                                    <img src="https://www.careem.com/pubweb-v2/ride/images/ccts/pooling.png" alt="icon"></img>
                                </div>
                                <div className="col-12 col-lg-6 contcategorytext">
                                    <h5> {t("titleCar4")} </h5>
                                    <p> {t("pCar4")} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Category;