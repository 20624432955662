/* eslint-disable jsx-a11y/alt-text */
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


const Header = ({ Title }) => {
    const [t, i18n] = useTranslation()
    return (
        <div className="head2">
            <div className="overlay"></div>
            <div className="container">
                <div className="textHead">
                    <h5>
                        {t(Title)}
                    </h5>
                </div>
            </div>
        </div>
        // <h2> {t('title')} </h2>
    );
}

export default Header;