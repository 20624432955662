import { useTranslation } from "react-i18next";

const Packages = () => {
    const [t] = useTranslation()
    return (
        <div className="packages">
            <div className="overlay"></div>
            <div className="container">
                <div className="packText">
                    <h5> {t("titlePackeg")} </h5>
                    <p>  {t("pPackeg")} </p>
                    <button>  {t("butPackeg")}</button>
                </div>
            </div>
        </div>
    );
}

export default Packages;