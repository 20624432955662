/* eslint-disable jsx-a11y/alt-text */
import { useTranslation } from "react-i18next";

const Advantages = () => {
    const [t] = useTranslation()
    return (
        <div className="advantages">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <img src="https://www.careem.com/pubweb-v2/ride/images/sec2-book-any-ride.png" />
                        <h4> {t('title_Advantages1')} </h4>
                        <p> {t('p_Advantages1')} </p>
                    </div>
                    <div className="col-12 col-md-4">
                        <img src="https://www.careem.com/pubweb-v2/ride/images/sec2-track-ride.png" />
                        <h4> {t('title_Advantages2')} </h4>
                        <p> {t('p_Advantages2')} </p>
                    </div>
                    <div className="col-12 col-md-4">
                        <img src="https://www.careem.com/pubweb-v2/ride/images/sec2-pay-as-you-like.png" />
                        <h4> {t('title_Advantages3')} </h4>
                        <p> {t('p_Advantages3')} </p>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Advantages
    ;