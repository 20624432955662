import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Sidbar = () => {
    const [t, i18n] = useTranslation()

    const toogleSidbar = () => {
        const sidbar = document.querySelector('.sidbar')
        sidbar.style.right = '-100%'
    }
    return (
        <div className="sidbar">
            <div className="close">
                <img src="./assets/Images/lorry-logo-white.png" />
                <i onClick={toogleSidbar} className="fa-regular fa-circle-xmark"></i>
            </div>
            <div className="contLink">
                <Link to='/about'>
                    {t('AboutUs')}
                </Link>
                <Link to='/safety'>
                    {t('Safety')}
                </Link>
                <Link to='/Joinourteam'>
                    {t('Joinourteam')}
                </Link>
                <Link to='/'>
                    {t('help')}
                </Link>
            </div>
        </div>
    );
}

export default Sidbar;