/* eslint-disable jsx-a11y/alt-text */
import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import Header from "./Header2";
import Navbar from "./Navbar";

const Safety = () => {
    const [t, i18n] = useTranslation()
    const Title = "titleSafety"

    return (
        <div className="safety">
            <div className="about">
                <Navbar />
                <Header Title={Title} />
                <div className="container">
                    <div className="subAbout">
                        <div className="aboutCont">
                            <p> {t("safety")} </p>
                        </div>
                        <div className="subsafety">
                            <h4> {t("safetySubTitle1")} </h4>
                            <div className="row align-items-center">
                                <div className="col-12 col-md-4">
                                    <div className="subsefetyChild">
                                        <img src="https://www.careem.com/pubweb-v2/safety/images/Customer/customer-1.jpg" />
                                        <div className="subsefetyCont">
                                            <h5> {t("Tbeforethetrip")} </h5>
                                            <p> {t("Pbeforethetrip")} </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="subsefetyChild">
                                        <img src="https://www.careem.com/pubweb-v2/safety/images/Customer/customer-2.jpg" />
                                        <div className="subsefetyCont">
                                            <h5> {t("Tduringthewalk")} </h5>
                                            <p> {t("Pduringthewalk")} </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="subsefetyChild">
                                        <img src="https://www.careem.com/pubweb-v2/safety/images/Customer/customer-3.jpg" />
                                        <div className="subsefetyCont">
                                            <h5> {t("Tduringthewalk")} </h5>
                                            <p> {t("Paftertheride")} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="subsafety">
                            <h4> {t("safetySubTitle2")} </h4>
                            <div className="row align-items-center">
                                <div className="col-12 col-md-4">
                                    <div className="subsefetyChild">
                                        <img src="https://www.careem.com/pubweb-v2/safety/images/Captain/captain-1.jpg" />
                                        <div className="subsefetyCont">
                                            <h5> {t("Tbeforethetrip")} </h5>
                                            <p> {t("Pbeforethetrip2")} </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="subsefetyChild">
                                        <img src="https://www.careem.com/pubweb-v2/safety/images/Captain/captain-2.jpg" />
                                        <div className="subsefetyCont">
                                            <h5> {t("Tduringthewalk")} </h5>
                                            <p> {t("Pduringthewalk2")} </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="subsefetyChild">
                                        <img src="https://www.careem.com/pubweb-v2/safety/images/Captain/captain-3.jpg" />
                                        <div className="subsefetyCont">
                                            <h5> {t("Tduringthewalk")} </h5>
                                            <p> {t("Paftertheride2")} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="aboutCont">
                            <p style={{ color: '#000', fontWeight: '500' }}> {t("safetyFoot")} </p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Safety;