/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Steps = () => {
    const [t] = useTranslation()
    const [imageUrl, setImageUrl] = useState("https://www.careem.com/pubweb-v2/ride/images/book-a-ride-step01.png")
    const allImageUrl = ["", "https://www.careem.com/pubweb-v2/ride/images/book-a-ride-step01.png", "https://www.careem.com/pubweb-v2/ride/images/book-a-ride-step02.png",
        "https://www.careem.com/pubweb-v2/ride/images/book-a-ride-step03.png", "https://www.careem.com/pubweb-v2/ride/images/book-a-ride-step04.png"
    ]

    useEffect(() => {
        const stepText = document.querySelectorAll('.stepText')
        stepText.forEach((item, index) => {
            item.addEventListener("click", function () {
                setImageUrl(allImageUrl[index + 1])
                stepText.forEach(item => {
                    item.classList.remove('active')
                })
                this.classList.add('active')
            })
        })
    }, [])

    return (
        <div className="steps">
            <div className="container">
                <h3> {t('titelStep')} </h3>
                <div className="stepCont">
                    <div className="row">
                        <div className="col-12 col-sm-5">
                            <div className="stepimg">
                                <img src={imageUrl} />
                            </div>
                        </div>
                        <div className="col-12 col-sm-7">
                            <div className="stepActive">
                                <div className="stepText active" >
                                    <div className="step-counter">
                                        <span>1</span>
                                    </div>
                                    <div className="step-body">
                                        <h5 className="step-title"> {t("titleStep1")} </h5>
                                        <p className="step-description">  {t("pStep1")} </p>
                                    </div>
                                </div>
                                <div className="stepText">
                                    <div className="step-counter">
                                        <span>2</span>
                                    </div>
                                    <div className="step-body">
                                        <h5 className="step-title"> {t("titleStep2")} </h5>
                                        <p className="step-description">  {t("pStep3")} </p>
                                    </div>
                                </div>
                                <div className="stepText">
                                    <div className="step-counter">
                                        <span>3</span>
                                    </div>
                                    <div className="step-body">
                                        <h5 className="step-title"> {t("titleStep3")} </h5>
                                        <p className="step-description">  {t("pStep3")} </p>
                                    </div>
                                </div>
                                <div className="stepText">
                                    <div className="step-counter">
                                        <span>4</span>
                                    </div>
                                    <div className="step-body">
                                        <h5 className="step-title"> {t("titleStep4")} </h5>
                                        <p className="step-description">  {t("pStep4")} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Steps;