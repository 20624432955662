import { useTranslation } from "react-i18next";

const Explore = () => {
    const [t] = useTranslation()
    return (
        <div className="explore">
            <h5> {t("explore")} </h5>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <div className="careem-story-card compact">
                            <div className="careem-story-card-body">
                                <h5 className="careem-story-card-title text-center">{t("titleExplore1")}</h5>
                                <p className="careem-story-card-text text-center"> {t("pExplore1")} </p>
                                <div className="text-center"><a href="/ar-ae/rewards" className="link"> {t("butPackeg")}  </a></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="careem-story-card compact">
                            <div className="careem-story-card-body">
                                <h5 className="careem-story-card-title text-center">{t("titleExplore2")}</h5>
                                <p className="careem-story-card-text text-center"> {t("pExplore2")} </p>
                                <div className="text-center"><a href="/ar-ae/rewards" className="link">{t("butPackeg")}</a></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="careem-story-card compact">
                            <div className="careem-story-card-body">
                                <h5 className="careem-story-card-title text-center">{t("titleExplore3")}</h5>
                                <p className="careem-story-card-text text-center"> {t("pExplore3")} </p>
                                <div className="text-center"><a href="/ar-ae/rewards" className="link"> {t("butPackeg")} </a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Explore;